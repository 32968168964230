import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import LoginHeader from "./LoginHeader";
import { apiInstance } from "../httpClient";
import { useHistory, useLocation } from "react-router-dom";
import { showToast } from "../common/ToastMsg";
import * as Yup from "yup";

export default function ResetPassword() {
  const [loding, setLoading] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [error, setError] = useState("");

  const history = useHistory();
  const location = useLocation();

  const getUrl = location.search;
  const getEmail = new URLSearchParams(getUrl).get("email");

  const togglePasswordVisiblity = (val) => {
    if (val === 1) {
      setNewPasswordShow(newPasswordShow ? false : true);
    } else if (val === 2) {
      setConfirmPasswordShow(confirmPasswordShow ? false : true);
    }
  };

  const initialValues = {
    email: getEmail,
    newpassword: "",
    confirmpassword: "",
  };

  const onSubmit = async (values) => {
    const resetData = {
      email: getEmail,
      password: values.newpassword,
    };
    setLoading(true);
    try {
      await apiInstance.post("auth/forgotPassword/setPassword", resetData);
      showToast("success", "Password updated successfully");
      setLoading(false);
      history.push("/login");
    } catch (error) {
      if (error.response.status === 404) {
        setError(error.response.data.message);
      } else if (error.response.status === 400) {
        setError(error.response.data.message);
      } else {
        setError("Something went wrong.please try again..");
      }
      setLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid Email").required("Required"),
    newpassword: Yup.string().required("Password is required"),
    confirmpassword: Yup.string().oneOf(
      [Yup.ref("newpassword"), null],
      "Passwords must match"
    ),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <div>
      {/* <Header/> */}
      <LoginHeader />
      <section ection className="p-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mx-auto">
              <div className="form-section p-60">
                <h1 className="mb-5 text-center">Reset password</h1>

                <form onSubmit={formik.handleSubmit}>
                  <div className="form-group">
                    <label for="name-f">Email Address</label>
                    <input
                      className="form-control"
                      id="email"
                      name="email"
                      type="email"
                      disabled
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div style={{ color: "#f00", textAlign: "left" }}>
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label for="name-f">New password</label>
                    <input
                      type={newPasswordShow ? "text" : "password"}
                      className="form-control"
                      id="newpassword"
                      label="newpassword"
                      name="newpassword"
                      {...formik.getFieldProps("newpassword")}
                    />
                    {formik.touched.newpassword && formik.errors.newpassword ? (
                      <div style={{ color: "#f00", textAlign: "left" }}>
                        {formik.errors.newpassword}
                      </div>
                    ) : null}

                    <span
                      className="hide-show"
                      onClick={() => {
                        togglePasswordVisiblity(1);
                      }}
                    >
                      {newPasswordShow ? "Hide" : "Show"}
                    </span>
                  </div>

                  <div className="form-group">
                    <label for="name-f">Confirm New password</label>
                    <input
                      type={confirmPasswordShow ? "text" : "password"}
                      className="form-control"
                      id="confirmpassword"
                      //    onBlur={formik.handleBlur}
                      label="confirmpassword"
                      name="confirmpassword"
                      {...formik.getFieldProps("confirmpassword")}
                      //    value={formik.values.password}
                      //    onChange={formik.handleChange}
                    />
                    {formik.touched.confirmpassword &&
                    formik.errors.confirmpassword ? (
                      <div style={{ color: "#f00", textAlign: "left" }}>
                        {formik.errors.confirmpassword}
                      </div>
                    ) : null}

                    <span
                      className="hide-show"
                      onClick={() => {
                        togglePasswordVisiblity(2);
                      }}
                    >
                      {confirmPasswordShow ? "Hide" : "Show"}
                    </span>
                  </div>
                  {error && (
                    <p style={{ color: "#F00", display: "block" }}>{error}</p>
                  )}
                  <Button className="bluebtn mt-4" type="sumbit">
                    Change password
                    {loding && (
                      <CircularProgress
                        size={15}
                        color="inherit"
                        style={{ marginLeft: "10px" }}
                      />
                    )}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
