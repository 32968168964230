import React, { useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";

export default function AboutUs() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <section className="home-banner about-us-banner p-100">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-12 mx-auto text-center home-content">
              <h1>Let’s build your ADU</h1>
              <p>
                Enter your property address to find ADU sizes for your lot size.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="about-us">
        <section className="p-60 text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-8 mx-auto">
                <h2 className="mb-5">ABOUT US</h2>
                <p>
                  HousingADU is one-stop shop for anyone interested in having an
                  ADU built on their property property. With the growing need
                  for housing in the United States, we have provided a way for
                  homeowners to build ADUs on their property with little to no
                  stress.
                </p>
                <h4>
                  <b>The app works in the following ways:</b>
                </h4>
                <br />
                <ul style={{ listStyle: "none", textAlign: "left" }}>
                  <li>
                    1. Drastically reduce the timeline and process of building
                    ADU by supplying the options of pre-approved plans. This
                    does away with the cost of architect-drawn plans and permit
                    processes with their attendant costs and more than 6 months
                    to 1 year process.
                  </li>
                  <br />
                  <li>
                    2. Giving home owners financing options and reducing the
                    cost of building ADU
                  </li>
                  <br />
                  <li>
                    3. Promoting the adoption of ADUs by letting homeowners know
                    about the opportunity when it exists around them
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
