import React, { useState, useEffect } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { apiInstance } from "../httpClient";
import { useHistory } from "react-router-dom";
import { showToast } from "../common/ToastMsg";
import * as Yup from "yup";
import Footer from "./Footer";
import Header from "./Header";
import { removeFilterData } from "./Common";

export default function Contact() {
  const [loding, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    removeFilterData();
  }, []);

  const initialValues = {
    fullname: "",
    email: "",
    subject: "",
    message: "",
  };
  const validationSchema = Yup.object().shape({
    fullname: Yup.string().required("Required"),
    email: Yup.string().email("Invalid Email").required("Required"),
    subject: Yup.string().required("Required"),
    message: Yup.string().required("Required"),
  });

  const onSubmit = async (values) => {
    const contactData = {
      name: values.fullname,
      email: values.email,
      subject: values.subject,
      message: values.message,
    };
    setLoading(true);
    try {
      const res = await apiInstance.post("contact/create", contactData);
      showToast("success", "your message submitted successfully");
      setLoading(false);
      history.push("/login");
    } catch (e) {
      showToast("error", "Something went wrong please try again");
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <div>
      <Header />

      <section ection className="p-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mx-auto">
              <div className="form-section p-60">
                <h1 className="mb-4 text-center">Contact US</h1>

                <form onSubmit={formik.handleSubmit}>
                  <div className="form-group">
                    <label for="name-f">Your Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="fullname"
                      name="fullname"
                      {...formik.getFieldProps("fullname")}
                    />
                    {formik.touched.fullname && formik.errors.fullname ? (
                      <div style={{ color: "#f00", textAlign: "left" }}>
                        {formik.errors.fullname}
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label for="name-f">Email Address</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      id="email"
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div style={{ color: "#f00", textAlign: "left" }}>
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label for="name-f">Subject</label>
                    <input
                      type="text"
                      className="form-control"
                      id="subject"
                      name="subject"
                      {...formik.getFieldProps("subject")}
                    />
                    {formik.touched.subject && formik.errors.subject ? (
                      <div style={{ color: "#f00", textAlign: "left" }}>
                        {formik.errors.subject}
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label for="name-f">Message</label>
                    <input
                      type="text"
                      className="form-control"
                      id="message"
                      name="message"
                      {...formik.getFieldProps("message")}
                    />
                    {formik.touched.message && formik.errors.message ? (
                      <div style={{ color: "#f00", textAlign: "left" }}>
                        {formik.errors.message}
                      </div>
                    ) : null}
                  </div>

                  <Button className="bluebtn mt-4" type="sumbit">
                    Submit
                    {loding && (
                      <CircularProgress
                        size={15}
                        color="inherit"
                        style={{ marginLeft: "10px" }}
                      />
                    )}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
