import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import heartfill from "../images/heart-fill.png";
import { CircularProgress, Button } from "@mui/material";
import Footer from "./Footer";
import { apiInstance } from "../httpClient";
import { getToken, removeFilterData } from "./Common";
import { showToast } from "../common/ToastMsg";
import InfiniteScroll from "react-infinite-scroll-component";

export default function SavedPlan() {
  const [plan, setPlans] = useState([]);
  const [error, setError] = useState("");
  const [loding, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
    // if(getToken == null){
    //     // history.push('/login')
    // }else{

    getSavedPlan();
    removeFilterData();
    // }
  }, []);

  const getNextSavedPlan = async () => {
    if (plan.length === totalCount) {
      setHasMore(false);
      return;
    }
    if (totalCount > plan.length) {
      setLoading(true);
      try {
        const { data } = await apiInstance.get(
          `favorite/getAll?pagesize=8&pagenumber=${pageNumber + 1}`,
          {
            headers: {
              Authorization: getToken(),
            },
          }
        );
        setPageNumber(pageNumber + 1);
        setTotalCount(data.total);
        setPlans([...plan, ...data.data]);
        setLoading(false);
      } catch (error) {
        if (error.response.status === 404) {
          setError(error.response.data.message);
        } else if (error.response.status === 400) {
          setError(error.response.data.message);
        } else {
          setError("Something went wrong.please try again.");
        }
        setLoading(false);
      }
    }
  };

  const getSavedPlan = async () => {
    setHasMore(true);
    setPageNumber(1);
    setTotalCount(2);

    // if (plan.length === totalCount) {
    //   setHasMore(false);
    //   return;
    // }

    setLoading(true);
    try {
      const { data } = await apiInstance.get(
        `favorite/getAll?pagesize=8&pagenumber=1`,
        {
          headers: {
            Authorization: getToken(),
          },
        }
      );
      // setPageNumber(pageNumber + 1);
      setTotalCount(data.total);
      setPlans(data.data);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 404) {
        setError(error.response.data.message);
      } else if (error.response.status === 400) {
        setError(error.response.data.message);
      } else {
        setError("Something went wrong.please try again.");
      }
      setLoading(false);
    }

    // if (totalCount > plan.length) {

    // }
  };

  const removeFavourite = async (id) => {
    try {
      await apiInstance.delete(`favorite/delete/${id}`, {
        headers: {
          Authorization: getToken(),
        },
      });
      showToast("success", "Adu removed successfully from your favourite list");
      getSavedPlan();
    } catch (e) {
      if (error.response.status === 404) {
        setError(error.response.data.message);
      } else if (error.response.status === 400) {
        setError(error.response.data.message);
      } else {
        setError("Something went wrong.please try again.");
      }
    }
  };
  return (
    <>
      <Header />

      <section className="adu-plan-section p-100">
        <div className="p-60">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-10 col-lg-11 col-md-11 col-12 mx-auto">
                <div className="row">
                  <div className="title-box text-center">
                    <h2>Explore various ADU plans</h2>
                  </div>
                </div>

                {loding ? (
                  <>
                    <div className="row">
                      <div className="col-lg-12">
                        <div
                          style={{
                            width: "100%",
                            height: "500px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CircularProgress
                            size={60}
                            color="inherit"
                            style={{ marginLeft: "10px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="plan-main-box">
                      {plan.length > 0 ? (
                        <InfiniteScroll
                          dataLength={plan.length}
                          next={getNextSavedPlan}
                          hasMore={hasMore}
                          loader={<h4 className="loadertext">Loading...</h4>}
                        >
                          <>
                            {plan.map((ele) => {
                              return (
                                <>
                                  <div className="plan-saved-grid">
                                    <div className="planbox">
                                      <Link
                                        to={{
                                          pathname: "/product-detail",
                                          state: ele,
                                          type: "saved",
                                        }}
                                      >
                                        <div className="box">
                                          <div className="img-box">
                                            <img
                                              src={ele.adu.image[0]}
                                              alt="img1"
                                            />
                                          </div>
                                          <div className="content">
                                            <div className="d-flex justify-content-between">
                                              <h4>{ele.adu.adu_name}</h4>
                                              {ele?.adu?.price ? (
                                                <h4 className="price">
                                                  ${ele.adu.price}
                                                </h4>
                                              ) : null}
                                            </div>
                                            {ele?.adu?.lotSize ? (
                                              <p>
                                                Lot size: {ele.adu.lotSize} Sq.
                                                Ft
                                              </p>
                                            ) : null}
                                          </div>
                                        </div>
                                      </Link>
                                      <span
                                        className="liked"
                                        onClick={() => removeFavourite(ele._id)}
                                      >
                                        <img src={heartfill} alt="img" />
                                      </span>
                                    </div>

                                    {error && (
                                      <p
                                        style={{
                                          color: "#F00",
                                          display: "block",
                                        }}
                                      >
                                        {error}
                                      </p>
                                    )}
                                  </div>
                                </>
                              );
                            })}
                          </>
                        </InfiniteScroll>
                      ) : (
                        <>
                          <div
                            className="no-data-found"
                            style={{ flexDirection: "column" }}
                          >
                            <p>No favourite ADU found</p>
                            <br />
                            <Button
                              component={Link}
                              to="/"
                              variant="contained"
                              size="medium"
                              className="bluebtn"
                            >
                              Explore
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
