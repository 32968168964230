import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import img1 from "../images/find.png";
import img2 from "../images/connect.png";
import img3 from "../images/build 1.png";
import Footer from "./Footer";
import Header from "./Header";
import { apiInstance } from "./../httpClient/index";
import { getToken, removeFilterData } from "./Common";
import AduListComponent from "./AduListComponent";

export default function Home(props) {
  const [plan, setPlans] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [suggestion, setSuggestion] = useState([]);
  const [error, setError] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(1);
  const [loding, setLoading] = useState(false);

  const history = useHistory();
  const token = getToken();

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllPlan();
    removeFilterData();
  }, []);

  const getAllPlan = async () => {
    if (plan.length === totalCount) {
      setHasMore(false);
      return;
    }

    if (totalCount > plan.length) {
      if (!token) {
        setLoading(true);
        try {
          const { data } = await apiInstance.get(
            `adu/?pagenumber=${pageNumber + 1}&pagesize=8`
          );

          setPageNumber(pageNumber + 1);
          setTotalCount(data.total);
          setPlans([...plan, ...data.data]);
          setLoading(false);
        } catch (error) {
          if (error.response.status === 404) {
            setError(error.response.data.message);
          } else if (error.response.status === 400) {
            setError(error.response.data.message);
          } else {
            setError("Something went wrong.please try again.");
          }
        }
      } else {
        setLoading(true);
        try {
          const { data } = await apiInstance.get(
            `adu/getAll?pagenumber=${pageNumber + 1}&pagesize=8`,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          setPageNumber(pageNumber + 1);
          setPlans([...plan, ...data.data]);
          setTotalCount(data.total);
          setLoading(false);
        } catch (error) {
          if (error.response.status === 404) {
            setError(error.response.data.message);
          } else if (error.response.status === 400) {
            setError(error.response.data.message);
          } else {
            setError("Something went wrong.please try again.");
          }

          setLoading(false);
        }
      }
    }
  };
  const handleSearch = async (e) => {
    const val = e.target.value;
    setTimeout(async () => {
      try {
        const res = await apiInstance.get(`suggestions?address=${val}`);
        setSuggestion(res.data.data);
      } catch (e) {
        if (error.response.status === 404) {
          setError(error.response.data.message);
        } else if (error.response.status === 400) {
          setError(error.response.data.message);
        } else {
          setError("Something went wrong.please try again.");
        }
      }
    }, 1000);

    setSearchValue(val);
  };

  const handleSuggestion = async (val) => {
    if (getToken() == null) {
      try {
        await apiInstance.get(`adu/?address=${val}&pagenumber=1&pagesize=8`);
        setSearchValue(val);
        history.push({
          pathname: "/adu-plans",
          state: val,
        });
      } catch (error) {
        setError("Something went wrong.please try again.");
      }
    } else {
      try {
        await apiInstance.get(
          `adu/getAll?address=${val}&pagenumber=1&pagesize=8`,
          {
            headers: {
              Authorization: getToken(),
            },
          }
        );
        setSearchValue(val);
        history.push({
          pathname: "/adu-plans",
          state: val,
        });
      } catch (error) {
        setError("Something went wrong.please try again.");
      }
    }
  };

  return (
    <>
      <Header />
      <section className="home-banner p-100">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto text-center home-content">
              <h1>Let’s build your ADU</h1>
              <p>
                Enter your property address to find ADU sizes for your lot size.
              </p>

              <div className="searchfield">
                <input
                  type="text"
                  className={`${
                    suggestion.length > 0
                      ? "custom-input1 form-control"
                      : "custom-input form-control"
                  }`}
                  placeholder="Enter property here.."
                  value={searchValue}
                  onChange={handleSearch}
                />
                <Button
                  component={Link}
                  className="serachbtn bluebtn"
                  to={{
                    pathname: "/adu-plans",
                    state: searchValue,
                  }}
                  // to='/adu-plans'
                >
                  Search
                </Button>
                {suggestion.length > 0 ? (
                  <div className="suggestion-box">
                    {suggestion.map((val) => {
                      return (
                        <div
                          onClick={() => handleSuggestion(val)}
                          className="suggestion"
                        >
                          {val}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="work-section p-60">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-10 col-lg-11 col-md-11 col-12 mx-auto">
              <div className="row">
                <div className="title-box text-center">
                  <h2>How it works</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 text-center">
                  <div className="box">
                    <img src={img1} alt="img1" className="mb-4" />
                    <h3>Find an ADU plan</h3>
                    <p>
                      Look through a variety of previously built and
                      pre-approved ADU plans to find the perfect plan for your
                      needs.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 text-center">
                  <div className="box">
                    <img src={img2} alt="img1" className="mb-4" />
                    <h3>Connect to a contractor</h3>
                    <p>
                      As soon as you choose an ADU plan, we connect contractors
                      who will help you build the plan to you.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 text-center">
                  <div className="box">
                    <img src={img3} alt="img1" className="mb-4" />
                    <h3>Build in record time</h3>
                    <p>
                      Bypass the long wait to building your ADU and start
                      getting returns on your new property at the earliest time
                      possible.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="adu-plan-section p-60">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-10 col-lg-11 col-md-11 col-12 mx-auto">
              <div className="row">
                <div className="title-box text-center">
                  <h2>Explore various ADU plans</h2>
                </div>
              </div>

              <div className="row">
                {plan.slice(0, 6).map((ele, index) => {
                  return (
                    <>
                      <div className="col-lg-4 col-md-6 col-12 mb-4">
                        <AduListComponent data={ele} />
                      </div>
                    </>
                  );
                })}
              </div>

              <div className="row justify-content-center">
                <Button
                  component={Link}
                  variant="contained"
                  to="/adu-plans"
                  size="medium"
                  className="bluebtn"
                >
                  View all plans
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
