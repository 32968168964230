import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import LoginHeader from "./LoginHeader";
import { apiInstance } from "../httpClient";
import { useHistory } from "react-router-dom";
import { showToast } from "../common/ToastMsg";

export default function ForgotPassword() {
  const [loding, setLoading] = useState(false);
  const history = useHistory();

  const initialValues = {
    email: "",
  };

  const onSubmit = async (values) => {
    const data = {
      email: values.email,
    };
    setLoading(true);
    try {
      await apiInstance.post(`auth/forgotPassword`, data);

      // setSuccessMsg("we have send a link to your email address");
      showToast("success", "we have send a link to your email address");
      setLoading(false);
      history.push("/login");
    } catch (e) {
      showToast("error", "Something went wrong please try again");
    }
  };

  const validate = (values) => {
    let errors = {};

    if (!values.email) {
      errors.email = "This fiels is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  return (
    <div>
      {/* <Header/> */}
      <LoginHeader />
      <section ection className="p-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mx-auto">
              <div className="form-section p-60">
                <h1 className="mb-4 text-center">Forgot password</h1>
                <p className="text-center px-3 mb-5">
                  Enter your email and we’ll send you a link to reset your
                  password.
                </p>
                <form onSubmit={formik.handleSubmit}>
                  <div className="form-group">
                    <label for="name-f">Email Address</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Enter your Email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  {formik.touched.email && formik.errors.email ? (
                    <div style={{ color: "#f00", textAlign: "left" }}>
                      {formik.errors.email}
                    </div>
                  ) : null}

                  <Button className="bluebtn mt-4" type="sumbit">
                    Send link to email
                    {loding && (
                      <CircularProgress
                        size={15}
                        color="inherit"
                        style={{ marginLeft: "10px" }}
                      />
                    )}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
