import "./App.css";
import "./style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AduPlans from "./Components/AduPlans";
import Contact from "./Components/Contact";
import Login from "./Components/Login";
import CreateAccount from "./Components/CreateAccount";
import Home from "./Components/Home";

import ForgotPassword from "./Components/ForgotPassword";
import SavedPlan from "./Components/SavedPlan";
import ProductDetail from "./Components/ProductDetail";
import EditProfile from "./Components/EditProfile";
import ChangePassword from "./Components/ChangePassword";
import { Toast } from "./common/ToastMsg";
import ResetPassword from "./Components/ResetPassword";
import Privacy from "./Components/Privacy";
import AboutUs from "./Components/AboutUs";
import { getToken } from "./Components/Common";
import { apiInstance } from "./httpClient/index";

const theme = createTheme({
  typography: {
    fontFamily: ["Sarabun", "sans-serif"].join(","),
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

function App() {
  Object.assign(apiInstance.defaults.headers, {
    Authorization: getToken(),
  });

  return (
    <ThemeProvider theme={theme}>
      <main>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/adu-plans" component={AduPlans} exact />
          <Route path="/contact" component={Contact} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={CreateAccount} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/saved-plans" component={SavedPlan} />
          <Route path="/product-detail" component={ProductDetail} />
          <Route path="/edit-profile" component={EditProfile} />
          <Route path="/change-password" component={ChangePassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/about-us" component={AboutUs} />
          <Route component={Error} />
        </Switch>
      </main>
      {/* <Header/>
        <Register/> */}
      {/* <Home/> */}
      <Toast />
    </ThemeProvider>
  );
}

export default App;
