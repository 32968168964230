import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Toast = () => {
  return (
    <ToastContainer
      position="top-center"
      autoClose={2000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

export const showToast = (type, msg) => {
  if (type === "success") {
    toast.success(msg);
  } else if (type === "warning") {
    toast.warning(msg);
  } else if (type === "info") {
    toast.info(msg);
  } else if (type === "error") {
    toast.error(msg);
  } else {
    toast.success(msg);
  }
};
