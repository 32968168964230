import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Header from "./Header";
import { Button, Modal, Typography, Box } from "@mui/material";
import done from "../images/done.png";
import help from "../images/help.png";
import Footer from "./Footer";
import { apiInstance } from "../httpClient";
import { getToken } from "./Common";
import { useHistory } from "react-router-dom";
import { showToast } from "../common/ToastMsg";
import banner from "../images/banner-1.jpg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

export default function ProductDetail(props) {
  const [open, setOpen] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [solarOpen, setSolarOpen] = useState(false);
  const [financeOpen, setFinanceOpen] = useState(false);
  const [isSolar, setIsSolar] = useState(false);
  const [isFinance, setIsFinance] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (props.location?.type === "saved") {
      const images = props.location?.state?.adu?.image?.map((val) => {
        return {
          original: val,
          thumbnail: val,
        };
      });
      setImageList(images);
    } else {
      const images = props.location?.state?.image?.map((val) => {
        return {
          original: val,
          thumbnail: val,
        };
      });
      setImageList(images);
    }
  }, []);

  const handleOpen = () => {
    if (getToken() === null) {
      history.push("/login");
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => setOpen(false);

  const handleSolarOpen = () => {
    setIsSolar(true);
    setSolarOpen(true);

    setOpen(false);
  };

  const handleSolarNo = () => {
    setSolarOpen(true);
    setIsSolar(false);
    setOpen(false);
  };

  const handleSolarClose = () => setSolarOpen(false);
  // const handleFinanceClose = () => setSolarOpen(false);

  const handleFinanceOpen = () => {
    setFinanceOpen(true);
    setIsFinance(true);
    setOpen(false);
  };

  const handleFinanceNo = () => {
    setFinanceOpen(true);
    setIsFinance(false);
    setOpen(false);
  };

  const handleConfirm = async () => {
    const financeData = {
      isSolar: isSolar,
      isFinance: isFinance,
    };
    try {
      await apiInstance.post(
        `FinanceBuild/create/${props.location.state._id}`,
        financeData,
        {
          headers: {
            Authorization: getToken(),
          },
        }
      );
      showToast(
        "info",
        "Your inquiry submitted successfully,our team will contact you soon."
      );
    } catch (error) {
      if (error.response.status === 404) {
        setError(error.response.data.message);
      } else if (error.response.status === 400) {
        setError(error.response.data.message);
      } else {
        setError("Something went wrong.please try again.");
      }
    }
    setOpen(false);
    setFinanceOpen(false);
    setSolarOpen(false);
  };
  const handleFinanceClose = () => setFinanceOpen(false);
  return (
    <>
      <Header />
      <section className="p-100">
        <div className="product-detail container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              {imageList.length > 0 ? (
                <ImageGallery
                  items={imageList}
                  showPlayButton={false}
                  showFullscreenButton={false}
                />
              ) : (
                <img src={banner} alt="img" />
              )}

              <div className="product-content">
                <h2 className="mb-4">
                  {props.location?.type === "saved"
                    ? props?.location?.state?.adu?.adu_name
                    : props?.location?.state?.adu_name}
                </h2>
                {props?.location?.state?.adu?.price ||
                props?.location?.state?.price ? (
                  <h3 className="mb-4">
                    $
                    {props.location?.type === "saved"
                      ? props?.location?.state?.adu?.price
                      : props?.location?.state?.price}
                  </h3>
                ) : null}

                <div className="d-flex justify-content-between">
                  <p className="mb-3">
                    {props.location?.type === "saved"
                      ? props?.location?.state?.adu?.bedRooms === "0BHK"
                        ? "0 Bedroom"
                        : props?.location?.state?.adu?.bedRooms === "1BHK"
                        ? "1 Bedroom"
                        : props?.location?.state?.adu?.bedRooms === "2BHK"
                        ? "2 Bedrooms"
                        : props?.location?.state?.adu?.bedRooms === "3BHK"
                        ? "3 Bedrooms"
                        : props?.location?.state?.adu?.bedRooms === "4BHK"
                        ? "4 Bedrooms"
                        : props?.location?.state?.adu?.bedRooms === "5BHK"
                        ? "5 Bedrooms"
                        : props?.location?.state?.adu?.bedRooms === "6BHK"
                        ? "6 Bedrooms"
                        : null
                      : props?.location?.state?.bedRooms === "0BHK"
                      ? "0 Bedroom"
                      : props?.location?.state?.bedRooms === "1BHK"
                      ? "1 Bedroom"
                      : props?.location?.state?.bedRooms === "2BHK"
                      ? "2 Bedrooms"
                      : props?.location?.state?.bedRooms === "3BHK"
                      ? "3 Bedrooms"
                      : props?.location?.state?.bedRooms === "4BHK"
                      ? "4 Bedrooms"
                      : props?.location?.state?.bedRooms === "5BHK"
                      ? "5 Bedrooms"
                      : props?.location?.state?.bedRooms === "6BHK"
                      ? "6 Bedrooms"
                      : null}
                  </p>
                  <p className="mb-3">
                    <b>Footprint: </b>
                    {props.location?.type === "saved"
                      ? props?.location?.state?.adu?.footprint
                      : props?.location?.state?.footprint}
                    Sq. Ft
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="mb-3">
                    {props.location?.type === "saved"
                      ? props?.location?.state?.adu?.approvals
                      : props?.location?.state?.approvals}
                  </p>

                  {props?.location?.state?.adu?.lotSize ||
                  props?.location?.state?.lotSize ? (
                    <p className="mb-3">
                      <b>Lot Size: </b>
                      {props.location?.type === "saved"
                        ? props?.location?.state?.adu?.lotSize
                        : props?.location?.state?.lotSize}
                      Sq. Ft
                    </p>
                  ) : null}
                </div>
                <div className="desc">
                  <h2>Description</h2>
                  <p>
                    {props.location?.type === "saved"
                      ? props?.location?.state?.adu?.description
                      : props?.location?.state?.description}
                  </p>
                </div>
                <div className="location">
                  <h2>Address</h2>
                  <p>
                    {props.location?.type === "saved"
                      ? props?.location?.state?.adu?.address
                      : props?.location?.state?.address}
                  </p>

                  <p>
                    {props.location?.type === "saved"
                      ? props?.location?.state?.adu?.city
                      : props?.location?.state?.city}
                  </p>
                  <p>
                    {props.location?.type === "saved"
                      ? props?.location?.state?.adu?.state?.name
                      : props?.location?.state?.state?.name}
                  </p>
                  <p>
                    {props.location?.type === "saved"
                      ? props?.location?.state?.adu?.county
                      : props?.location?.state?.county}
                  </p>
                  <p>
                    {props.location?.type === "saved"
                      ? props?.location?.state?.adu?.zipCode
                      : props?.location?.state?.zipCode}
                  </p>
                </div>

                {error && (
                  <p style={{ color: "#F00", display: "block" }}>{error}</p>
                )}
                <Button className="bluebtn" onClick={handleOpen}>
                  Choose plan
                </Button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  // onClose={(event, reason) => {
                  //   if (reason !== "backdropClick") {
                  //     handleClose(event, reason);
                  //   }
                  // }}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} className="help-modal">
                    <img src={done} alt="img" />

                    <Typography
                      id="modal-modal-description"
                      variant="h5"
                      component="h1"
                      marginTop="15px"
                      // sx={{ mb: 4 }}
                    >
                      Do you want to add Solar in this build?
                    </Typography>
                    <div className="d-flex justify-content-between">
                      <Button className="bluebtn" onClick={handleSolarNo}>
                        No
                      </Button>
                      <Button className="bluebtn" onClick={handleSolarOpen}>
                        Yes
                      </Button>
                    </div>
                  </Box>
                </Modal>

                <Modal
                  open={solarOpen}
                  // onClose={handleClose}
                  onClose={handleSolarClose}
                  // onClose={(event, reason) => {
                  //   if (reason !== "backdropClick") {
                  //     handleSolarClose(event, reason);
                  //   }
                  // }}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} className="help-modal">
                    <img src={help} alt="img" />

                    <Typography
                      id="modal-modal-title"
                      variant="h5"
                      component="h1"
                      marginTop="15px"
                    >
                      Do you want to finance this build?
                    </Typography>
                    <div className="d-flex justify-content-between">
                      <Button className="bluebtn" onClick={handleFinanceNo}>
                        No
                      </Button>
                      <Button className="bluebtn" onClick={handleFinanceOpen}>
                        Yes
                      </Button>
                    </div>
                  </Box>
                </Modal>

                <Modal
                  open={financeOpen}
                  onClose={handleClose}
                  // onClose={handleFinanceClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} className="done-modal">
                    <img src={done} alt="img" />

                    <Typography
                      id="modal-modal-title"
                      variant="h5"
                      component="h1"
                      marginTop="15px"
                    >
                      Plan choice confirmed
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mb: 4 }}>
                      An email has been sent to you. We’ll get back to you right
                      away.
                    </Typography>
                    <Button className="bluebtn" onClick={handleConfirm}>
                      Confirm
                    </Button>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
