import React from "react";
import { Button } from "@mui/material";
import { Link, useHistory } from "react-router-dom";

export default function Footer() {
  const history = useHistory();
  const goToHome = () => {
    history.push("/");
    window.scrollTo(0, 0);
  };
  return (
    <>
      <footer className="p-60">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12 mx-auto">
              <div className="footer-content text-center">
                <h1>Build your ADU in record time</h1>
                <p>
                  Get connected to a contractor in as little as 5 minutes,
                  receive financing for your build and fit your ADU with extra
                  amenities all on the same app.
                </p>
                <Button
                  // component={Link}
                  // to="/"
                  variant="contained"
                  size="medium"
                  className="bluebtn"
                  onClick={goToHome}
                >
                  Get Started
                </Button>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="bottom-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="footer-nav">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/adu-plans">ADU Plans</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About us</Link>
                  </li>
                  <li>
                    <Link to="/">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 text-center text-lg-end col-md-12 col-12 d-flex justify-content-end flex-column flex-md-row bottom-section">
              <Link to="/privacy" className="me-2">
                Privacy Policy
              </Link>
              <p className="m-0">© 2021 Housing ADU. All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
