import React from "react";
// import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link as RouterLink } from "react-router-dom";
import logo1 from "../images/logo.png";

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "#fff !important",
    paddingRight: "79px",
    paddingLeft: "118px",
    paddingTop: "10px",
    paddingBottom: "10px",
    boxShadow: "none !important",

    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  logo: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#FFFEFE",
    textAlign: "left",
  },

  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "20px 30px",
  },
}));

export default function LoginHeader() {
  const { header, toolbar } = useStyles();

  return (
    <>
      <header className="container">
        <AppBar className={header}>
          {/* {mobileView ? displayMobile() : displayDesktop()} */}
          <Toolbar className={toolbar}>
            <Link to="/" component={RouterLink}>
              <img src={logo1} alt="logo" className="logo-img" />
            </Link>
          </Toolbar>
        </AppBar>
      </header>
    </>
  );
}
