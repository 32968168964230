import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";

import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";
import LoginHeader from "./LoginHeader";
import house from "../images/house.png";
import { setUserSession } from "./Common";
import { apiInstance } from "./../httpClient/index";
import { setToken } from "../httpClient/ClientHelper";

const CountrySelect = ({ value, onChange, labels, ...rest }) => (
  <select
    {...rest}
    value={value}
    onChange={(event) => onChange(event.target.value || undefined)}
  >
    <option value="">{labels["ZZ"]}</option>
    {getCountries().map((country) => (
      <option key={country} value={country}>
        +{getCountryCallingCode(country)}
      </option>
    ))}
  </select>
);
CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labels: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default function CreateAccount() {
  const [country, setCountry] = useState("US");

  const [passwordShow, setPasswordShow] = useState(false);
  const [error, setError] = useState("");
  const [loding, setLoading] = useState(false);
  let history = useHistory();

  const togglePasswordVisiblity = () => {
    setPasswordShow(passwordShow ? false : true);
  };

  const initialValues = {
    fullname: "",
    email: "",
    password: "",
    phone: "",
  };

  const onSubmit = async (values) => {
    const data = {
      name: values.fullname,
      email: values.email,
      phoneNumber: values.phone,
      countryCode: `+${getCountryCallingCode(country)}`,
      code: country,
      password: values.password,
    };

    setLoading(true);
    try {
      const res = await apiInstance.post(`user`, data);
      setToken(res.data.token);
      setUserSession(
        res.data.Token,
        res.data.data.name,
        res.data.data.email,
        res.data.data.phone,
        res.data.data
      );
      history.push("/login");
      setLoading(false);
    } catch (error) {
      if (error.response.status === 404) {
        setError(error.response.data.message);
      } else if (error.response.status === 400) {
        setError(error.response.data.message);
      } else {
        setError("not created");
      }
    }
  };

  const validationSchema = Yup.object().shape({
    fullname: Yup.string().required("Required..."),
    email: Yup.string().email("Invalid Email").required("Required"),
    password: Yup.string().required("Password is required"),
    phone: Yup.string().required("phone is required"),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <>
      {/* <Header/> */}
      <LoginHeader />

      <section className="create-account-page">
        <div className="d-flex align-items-center loginpage">
          <div className="imgside">
            <img src={house} alt="img" className="img-fluid" />
            <div className="imgcontent">
              <h1 className="mb-4">Build an ADU in record time</h1>
              <p>
                Want to build an ADU (accessory dwelling unit) without the long
                wait time? The Housing ADU app is your one-stop shop for
                everything you need while planning your build. Our repository of
                plans offers you choices which have been pre-approved, and saves
                you the time of going through the approval process alone.
              </p>
            </div>
          </div>
          <div className="content">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mx-auto">
                  <div className="form-section p-60">
                    <h1 className="mb-4 text-center">Create an account</h1>

                    <form onSubmit={formik.handleSubmit}>
                      <div className="form-group">
                        <label for="name-f">Your Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="fullname"
                          // onBlur={formik.handleBlur}
                          // label="fullname"
                          name="fullname"
                          // value={formik.values.name}
                          // onChange={formik.handleChange}
                          {...formik.getFieldProps("fullname")}
                        />
                        {formik.touched.fullname && formik.errors.fullname ? (
                          <div style={{ color: "#f00", textAlign: "left" }}>
                            {formik.errors.fullname}
                          </div>
                        ) : null}
                      </div>

                      <div className="form-group">
                        <label for="name-f">Email Address</label>
                        <input
                          className="form-control"
                          id="email"
                          name="email"
                          type="email"
                          {...formik.getFieldProps("email")}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div style={{ color: "#f00", textAlign: "left" }}>
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>

                      <div className="form-group">
                        <label for="name-f">Phone number</label>
                        <CountrySelect
                          labels={en}
                          value={country}
                          onChange={(val) => setCountry(val)}
                        />
                        <input
                          type="number"
                          className="form-control phoneinput"
                          id="phone"
                          name="phone"
                          {...formik.getFieldProps("phone")}
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                          <div style={{ color: "#f00", textAlign: "left" }}>
                            {formik.errors.phone}
                          </div>
                        ) : null}
                      </div>

                      <div className="form-group">
                        <label for="name-f">Password</label>
                        <input
                          type={passwordShow ? "text" : "password"}
                          className="form-control"
                          name="password"
                          id="password"
                          {...formik.getFieldProps("password")}
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <div style={{ color: "#f00", textAlign: "left" }}>
                            {formik.errors.password}
                          </div>
                        ) : null}

                        <span
                          className="hide-show"
                          onClick={togglePasswordVisiblity}
                        >
                          {passwordShow ? "Hide" : "Show"}
                        </span>
                      </div>

                      {error && (
                        <p style={{ color: "#F00", display: "block" }}>
                          {error}
                        </p>
                      )}

                      <p>
                        Creating an account means you agree to the
                        <a href="#"> Terms & Conditions</a> and
                        <a href="#"> Privacy Policy</a>
                      </p>

                      <Button className="bluebtn mt-4" type="submit">
                        Create An Account
                        {loding && (
                          <CircularProgress
                            size={15}
                            color="inherit"
                            style={{ marginLeft: "10px" }}
                          />
                        )}
                      </Button>
                    </form>
                    <div className="mt-4 text-center">
                      <p>
                        Already have an account? <a href="/login">Login</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
