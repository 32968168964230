import React, { useState, useEffect } from "react";
import Header from "./Header";
import { useHistory } from "react-router-dom";
import { Divider, Button, CircularProgress } from "@mui/material";

import Footer from "./Footer";
import { apiInstance } from "./../httpClient/index";
import { getFilterData, getSearchVal, getToken } from "./Common";
import { Toast, showToast } from "../common/ToastMsg";

import InfiniteScroll from "react-infinite-scroll-component";
import { OPTIONS, FILTER_DATA } from "./../utils/constant";
import AduListComponent from "./AduListComponent";
import { setFilterAllData, removeFilterData, getIsFilter } from "./Common";

export default function AduPlans(props) {
  const [sort, setSort] = useState(getFilterData()?.sortBy || OPTIONS[0].value);
  const [searchValue, setSearchValue] = useState(getSearchVal() || "");
  const [plan, setPlans] = useState([]);
  const [selectPrice, setSelectPrice] = useState(getFilterData()?.price || []);
  const [selectBedroom, setSelectBedroom] = useState(
    getFilterData()?.bedroom || []
  );
  const [selectFootprint, setSelectFootprint] = useState(
    getFilterData()?.footprint || []
  );
  const [selectApproval, setSelectApproval] = useState(
    getFilterData()?.approval || []
  );
  const [loding, setLoading] = useState(false);
  const [fondness, setFondness] = useState([]);
  const [error, setError] = useState("");
  const [suggestion, setSuggestion] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(2);
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState(false);
  const token = getToken();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (getIsFilter() === true) {
      handleFilter();
    } else if (!props?.location?.state?.length) {
      getAllPlan();
    } else {
      setSearchValue(props.location.state);
      searchResult(props.location.state);
    }
  }, []);

  const getAllPlan = async () => {
    if (!token) {
      setLoading(true);
      try {
        const { data } = await apiInstance.get(`adu/?pagenumber=1&pagesize=8`);

        setPlans(data.data);
        setTotalCount(data.total);
        setLoading(false);
      } catch (error) {
        if (error.response.status === 404) {
          setError(error.response.data.message);
        } else if (error.response.status === 400) {
          setError(error.response.data.message);
        } else {
          setError("Something went wrong.please try again.");
        }
      }
    } else {
      setLoading(true);
      try {
        const { data } = await apiInstance.get(
          `adu/getAll?pagenumber=1&pagesize=8`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        // setPageNumber(pageNumber + 1);
        setPlans(data.data);
        setTotalCount(data.total);
        setLoading(false);
      } catch (error) {
        if (error.response.status === 404) {
          setError(error.response.data.message);
        } else if (error.response.status === 400) {
          setError(error.response.data.message);
        } else {
          setError("Something went wrong.please try again.");
        }

        setLoading(false);
      }
    }
  };

  const handleChange = (event) => {
    const res = event.target.value;
    setSort(res);
  };

  const handleSearch = async (e) => {
    const val = e.target.value;
    setLoading(true);
    setTimeout(async () => {
      if (val.length > 0) {
        try {
          const res = await apiInstance.get(`suggestions?address=${val}`);
          setSuggestion(res.data.data);

          setLoading(false);
        } catch (error) {
          if (error.response.status === 404) {
            setError(error.response.data.message);
          } else if (error.response.status === 400) {
            setError(error.response.data.message);
          } else {
            setError("Something went wrong.please try again.");
          }
          setLoading(false);
        }
      } else {
        setSuggestion([]);
        if (getIsFilter() === true) {
          handleFilter();
        } else {
          getAllPlan();
        }
      }
    }, 1000);

    setSearchValue(val);
    // setSearchText("");
  };

  const handleSuggestion = async (val) => {
    setSearchText(val);
    setLoading(true);
    setHasMore(true);
    setPageNumber(1);
    setTotalCount(2);

    if (token) {
      try {
        const { data } = await apiInstance.get(
          `adu/getAll?address=${val}&pagenumber=1&pagesize=8`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        // setPageNumber(pageNumber + 1);
        setTotalCount(data.total);
        setSearchValue(val);
        setPlans(data.data);
        setSuggestion([]);
        setLoading(false);
      } catch (error) {
        if (error.response.status === 404) {
          setError(error.response.data.message);
        } else if (error.response.status === 400) {
          setError(error.response.data.message);
        } else {
          setError("Something went wrong.please try again.");
        }
      }
    } else {
      try {
        const { data } = await apiInstance.get(
          `adu/?address=${val}&pagenumber=1&pagesize=8`
        );
        // setPageNumber(pageNumber + 1);
        setTotalCount(data.total);
        setSearchValue(val);
        setPlans(data.data);
        setSuggestion([]);
        setLoading(false);
      } catch (error) {
        if (error.response.status === 404) {
          setError(error.response.data.message);
        } else if (error.response.status === 400) {
          setError(error.response.data.message);
        } else {
          setError("Something went wrong.please try again.");
        }
      }
    }
  };

  const getNextPlan = async () => {
    if (plan.length === totalCount) {
      setHasMore(false);
      return;
    }

    if (totalCount > plan.length) {
      setTimeout(async () => {
        if (!token) {
          setLoading(true);
          try {
            const { data } = await apiInstance.get(
              `adu/?address=${searchText}&pagenumber=${
                pageNumber + 1
              }&pagesize=8`
            );

            setPageNumber(pageNumber + 1);
            setTotalCount(data.total);
            setPlans([...plan, ...data.data]);
            setLoading(false);
          } catch (error) {
            if (error.response.status === 404) {
              setError(error.response.data.message);
            } else if (error.response.status === 400) {
              setError(error.response.data.message);
            } else {
              setError("Something went wrong.please try again.");
            }
          }
        } else {
          setLoading(true);
          try {
            const { data } = await apiInstance.get(
              `adu/getAll?address=${searchText}&pagenumber=${
                pageNumber + 1
              }&pagesize=8`,
              {
                headers: {
                  Authorization: token,
                },
              }
            );
            setPageNumber(pageNumber + 1);
            setPlans([...plan, ...data.data]);

            setTotalCount(data.total);
            setLoading(false);
          } catch (error) {
            if (error.response.status === 404) {
              setError(error.response.data.message);
            } else if (error.response.status === 400) {
              setError(error.response.data.message);
            } else {
              setError("Something went wrong.please try again.");
            }
          }
        }
      }, 1500);
    }
  };

  const handleNextGetFilter = async () => {
    if (plan.length === totalCount) {
      setHasMore(false);
      return;
    }
    if (getIsFilter() === true) {
      setIsFilter(true);
      setFilterAllData(
        true,
        searchValue,
        sort,
        selectPrice,
        selectBedroom,
        selectFootprint,
        selectApproval
      );
    }

    const isFilter = await getIsFilter();
    const filterAllData = await getFilterData();
    const searchVal = await getSearchVal();
    const search = isFilter ? searchVal : searchValue;
    const bedroom = isFilter ? filterAllData.bedroom : selectBedroom.join();
    const footpring = isFilter
      ? filterAllData.footprint
      : selectFootprint.join();

    const approval = isFilter ? filterAllData.approval : selectApproval.join();
    const price = isFilter ? filterAllData.price : selectPrice.join();

    const sort_by = isFilter ? filterAllData.sortBy : sort;

    if (totalCount > plan.length) {
      if (token) {
        try {
          setLoading(true);
          const { data } = await apiInstance.get(
            `filter?search=${search}&footprint=${footpring}&approvals=${approval}&bedRooms=${bedroom}&sort_by=${sort_by}&price=${price}&pagenumber=${
              pageNumber + 1
            }&pagesize=8`,
            // filter?search=${search}&footprint=${footpring}&approvals=${approval}&bedRooms=${bedroom}&sort_by=${sort_by}&price=${price}&pagenumber=1&pagesize=15
            {
              headers: {
                Authorization: token,
              },
            }
          );
          setPageNumber(pageNumber + 1);
          setTotalCount(data.total);
          setPlans([...plan, ...data.data]);

          setLoading(false);
        } catch (error) {
          if (error.response.status === 404) {
            setError(error.response.data.message);
          } else if (error.response.status === 400) {
            setError(error.response.data.message);
          } else {
            setError("Something went wrong.please try again.");
          }
          setLoading(false);
        }
      } else {
        setLoading(true);
        try {
          const { data } = await apiInstance.get(
            `filter/without_login?search=${search}&bedRooms=${bedroom}&footprint=${footpring}&approvals=${approval}&price=${price}&sort_by=${sort_by}&pagenumber=${
              pageNumber + 1
            }&pagesize=8`
          );
          setPageNumber(pageNumber + 1);
          setTotalCount(data.total);
          setPlans([...plan, ...data.data]);
          // setPlans(data.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    }
  };
  const searchResult = async (val) => {
    setSearchText(val);
    setLoading(true);
    setHasMore(true);
    setPageNumber(1);
    setTotalCount(2);

    if (token) {
      try {
        const { data } = await apiInstance.get(
          `adu/getAll?address=${val}&pagenumber=1&pagesize=8`
        );
        if (data) {
          // setPageNumber(pageNumber + 1);
          setTotalCount(data.total);
          setPlans(data.data);

          setSuggestion([]);
          setLoading(false);
        } else {
          console.log("no reslut found");
        }
        setLoading(false);
      } catch (error) {
        if (error.response.status === 404) {
          setError(error.response.data.message);
        } else if (error.response.status === 400) {
          setError(error.response.data.message);
        } else {
          setError("Something went wrong.please try again.");
        }
        setLoading(false);
      }
    } else {
      try {
        const { data } = await apiInstance.get(
          `adu/?address=${val}&pagenumber=1&pagesize=8`
        );
        if (data) {
          setTotalCount(data.total);
          setPlans(data.data);
          setSuggestion([]);
          setLoading(false);
        } else {
          console.log("no reslut found");
        }
        setLoading(false);
      } catch (error) {
        if (error.response.status === 404) {
          setError(error.response.data.message);
        } else if (error.response.status === 400) {
          setError(error.response.data.message);
        } else {
          setError("Something went wrong.please try again.");
        }
        setLoading(false);
      }
    }
  };
  const addFavourite = async (id, fav) => {
    if (token === null) {
      showToast("error", "you have to login first");
    } else if (fav === false) {
      try {
        const res = await apiInstance.post(`favorite/create/${id}`);
        if (isFilter === true) {
          handleFilter();
        } else {
          handleSuggestion(searchValue);
        }
        // handleSuggestion(searchValue);
        showToast("success", "Adu added in favourite sucessfully");
        // getAllPlan();
      } catch (error) {
        if (error.response.status === 404) {
          setError(error.response.data.message);
        } else if (error.response.status === 400) {
          setError(error.response.data.message);
        } else {
          setError("Something went wrong.please try again.");
        }
      }
    } else {
      try {
        await apiInstance.delete(`favorite/delete/adu/${id}`);
        if (isFilter === true) {
          handleFilter();
        } else {
          handleSuggestion(searchValue);
        }
        // handleSuggestion(searchValue);
        showToast("success", "Adu removed from favourite sucessfully");
      } catch (error) {
        if (error.response.status === 404) {
          setError(error.response.data.message);
        } else if (error.response.status === 400) {
          setError(error.response.data.message);
        } else {
          setError("Something went wrong.please try again.");
        }
      }
    }
  };

  const handleSelect = (ele, val) => {
    if (val === 1) {
      if (selectPrice.includes(ele.value)) {
        const ind = selectPrice.indexOf(ele.value);
        selectPrice.splice(ind, 1);
      } else {
        selectPrice.push(ele.value);
      }
    } else if (val === 2) {
      if (selectBedroom.includes(ele.value)) {
        const ind = selectBedroom.indexOf(ele.value);
        selectBedroom.splice(ind, 1);
      } else {
        selectBedroom.push(ele.value);
      }
    } else if (val === 3) {
      if (selectFootprint.length > 0) {
        // const ind = selectFootprint.indexOf(ele);
        const res = selectFootprint.splice(0, 1, ele.value);
        // selectFootprint.splice(ind, 1);
      } else {
        selectFootprint.push(ele.value);
      }
    } else if (val === 4) {
      if (selectApproval.includes(ele.value)) {
        const ind = selectApproval.indexOf(ele.value);
        selectApproval.splice(ind, 1);
      } else {
        selectApproval.push(ele.value);
      }
    }

    const i = [...FILTER_DATA];
    for (const data of i) {
      if (data === ele) {
        if (data.selected && data.selected === true) {
          data.selected = !data.selected;
          setFondness(i);
        } else {
          data.selected = true;
          setFondness(i);
        }
        break;
      }
    }
  };

  const filterprice = FILTER_DATA.filter((ele) => ele.category === "Price");

  const filterbed = FILTER_DATA.filter((ele) => ele.category === "bedroom");

  const filterfootprint = FILTER_DATA.filter(
    (ele) => ele.category === "footprint"
  );

  const filterapproval = FILTER_DATA.filter(
    (ele) => ele.category === "approval"
  );

  const resetFilter = () => {
    setSelectPrice([]);
    setSelectBedroom([]);
    setSelectFootprint([]);
    setSelectApproval([]);
    searchResult(searchValue);
    removeFilterData();
    // getAllPlan();
  };

  const handleFilter = async () => {
    setIsFilter(true);
    setFilterAllData(
      true,
      searchValue,
      sort,
      selectPrice,
      selectBedroom,
      selectFootprint,
      selectApproval
    );

    const isFilter = await getIsFilter();
    const filterAllData = await getFilterData();
    const searchVal = await getSearchVal();
    const search = isFilter ? searchVal : searchValue;
    const bedroom = isFilter ? filterAllData.bedroom : selectBedroom.join();
    const footpring = isFilter
      ? filterAllData.footprint
      : selectFootprint.join();

    const approval = isFilter ? filterAllData.approval : selectApproval.join();

    const price = isFilter ? filterAllData.price : selectPrice.join();

    const sort_by = isFilter ? filterAllData.sortBy : sort;

    setLoading(true);
    setHasMore(true);
    setPageNumber(1);
    setTotalCount(2);

    window.scrollTo(0, 0);
    if (token === null) {
      setLoading(true);
      try {
        const { data } = await apiInstance.get(
          `filter/without_login?search=${search}&bedRooms=${bedroom}&footprint=${footpring}&approvals=${approval}&price=${price}&sort_by=${sort_by}&pagenumber=1&pagesize=8`
        );
        setTotalCount(data.total);
        setPlans(data.data);
        setIsFilter(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const { data } = await apiInstance.get(
          `filter?search=${search}&footprint=${footpring}&approvals=${approval}&bedRooms=${bedroom}&sort_by=${sort_by}&price=${price}&pagenumber=1&pagesize=8`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        const res = await apiInstance.get(
          `filter?search=${search}&footprint=${footpring}&approvals=${approval}&bedRooms=${bedroom}&sort_by=${sort_by}&price=${price}&pagenumber=1&pagesize=8`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log("dattt-----------", res);

        setTotalCount(data.total);
        setPlans(data.data);

        // setIsFilter(true);
        setLoading(false);
        // setFilterAllData(
        //   true,
        //   searchValue,
        //   sort,
        //   selectPrice,
        //   selectBedroom,
        //   selectFootprint,
        //   selectApproval
        // );
      } catch (error) {
        if (error.response.status === 404) {
          setError(error.response.data.message);
        } else if (error.response.status === 400) {
          setError(error.response.data.message);
        } else {
          setError("Something went wrong.please try again.");
        }
        setLoading(false);
      }
    }
  };

  const { state } = props.location;

  return (
    <>
      <Header />
      <section className="p-100 find-plan adu-plan-section">
        <div className="container-fluid">
          <div className="col-xl -10 col-lg-11 col-md-11 col-11 mx-auto p-60">
            <div className="row">
              <div className="col-md-6 col-sm-12 col-12 mx-auto mb-5">
                <div className="searchfield">
                  <input
                    type="text"
                    className={`${
                      suggestion.length > 0
                        ? "custom-input1 form-control"
                        : "custom-input form-control"
                    }`}
                    placeholder="Enter property here.."
                    value={searchValue}
                    onChange={handleSearch}
                  />
                  <Button
                    className="serachbtn bluebtn"
                    onClick={() => searchResult(searchValue)}
                  >
                    Search
                    {loding && (
                      <CircularProgress
                        size={15}
                        color="inherit"
                        style={{ marginLeft: "10px" }}
                      />
                    )}
                  </Button>
                  {suggestion.length > 0 ? (
                    <div className="suggestion-box">
                      {suggestion.map((val) => {
                        return (
                          <div
                            onClick={() => handleSuggestion(val)}
                            className="suggestion"
                          >
                            {val}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="search-filter">
                  <div className="d-flex justify-content-between mb-3">
                    <h5>Filter search</h5>
                    <h5 className="reset" onClick={resetFilter}>
                      Reset filter
                    </h5>
                  </div>
                  <Divider variant="fullWidth" className="mb-4" />
                  <div className="form-group">
                    <label>
                      <b>Sort by</b>
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={sort}
                      onChange={handleChange}
                    >
                      {OPTIONS.map((option) => (
                        <option
                          value={option.value}
                          selected={sort === option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <label>
                      <b>Price range</b>
                    </label>
                    <div className="d-flex justify-content-start flex-wrap">
                      {filterprice.map((ele, index) => {
                        return (
                          <div key={index.toString()}>
                            <Button
                              className={
                                selectPrice.includes(ele.value)
                                  ? "activeblue"
                                  : "filter-btn"
                              }
                              onClick={() => handleSelect(ele, 1)}
                            >
                              {ele.value}
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="form-group">
                    <label>
                      <b>Bedroom</b>
                    </label>
                    <div className="d-flex justify-content-start flex-wrap">
                      {filterbed.map((ele, index) => {
                        return (
                          <div key={index.toString()}>
                            <Button
                              className={
                                selectBedroom.includes(ele.value)
                                  ? "activeblue"
                                  : "filter-btn"
                              }
                              onClick={() => handleSelect(ele, 2)}
                            >
                              {ele.label}
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="form-group">
                    <label>
                      <b>Maximum footprint</b>
                    </label>
                    <div className="d-flex justify-content-start flex-wrap">
                      {filterfootprint.map((ele, index) => {
                        return (
                          <div key={index.toString()}>
                            <Button
                              key={index}
                              className={
                                selectFootprint.includes(ele.value)
                                  ? "activeblue"
                                  : "filter-btn"
                              }
                              onClick={() => handleSelect(ele, 3)}
                            >
                              {ele.value}
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="form-group">
                    <label>
                      <b>Approvals</b>
                    </label>
                    <div className="d-flex justify-content-start flex-wrap">
                      {filterapproval.map((ele, index) => {
                        return (
                          <div key={index.toString()}>
                            <Button
                              key={index}
                              className={
                                selectApproval.includes(ele.value)
                                  ? "activeblue"
                                  : "filter-btn"
                              }
                              onClick={() => handleSelect(ele, 4)}
                            >
                              {ele.value}
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <Button className="bluebtn savebtn" onClick={handleFilter}>
                    Save
                  </Button>
                </div>
              </div>
              <div className="col-lg-8">
                {error && (
                  <p style={{ color: "#F00", display: "block" }}>{error}</p>
                )}

                {loding ? (
                  <>
                    <div className="row">
                      <div className="col-lg-12">
                        <div
                          style={{
                            width: "100%",
                            height: "500px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CircularProgress
                            size={60}
                            color="inherit"
                            style={{ marginLeft: "10px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="record">
                          <p>{totalCount} plans found</p>
                        </div>
                      </div>
                    </div>
                    <div className="plan-main-box">
                      {plan.length > 0 ? (
                        <InfiniteScroll
                          dataLength={plan.length}
                          next={isFilter ? handleNextGetFilter : getNextPlan}
                          hasMore={hasMore}
                          loader={<h4>Loading...</h4>}
                        >
                          {plan.map((ele) => {
                            return (
                              <AduListComponent
                                data={ele}
                                isFilter={isFilter}
                              />
                            );
                          })}
                        </InfiniteScroll>
                      ) : (
                        <>
                          <div
                            className="no-data-found"
                            style={{ minHeight: "500px" }}
                          >
                            <p>No result found</p>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
