export const BASE_URL = "https://api.housingadu.com/v1";
// export const BASE_URL = "http://192.168.0.111:2525/v1/";

export const OPTIONS = [
  {
    label: "Lowest to highest",
    value: "Lowest to highest",
  },
  {
    label: "Highest to lowest",
    value: "Highest to lowest",
  },
];

export const FILTER_DATA = [
  {
    id: 1,
    category: "Price",
    value: "$50k & below",
  },
  {
    id: 2,
    category: "Price",
    value: "$50k - $100k",
  },
  {
    id: 3,
    category: "Price",
    value: "$100k - $150k",
  },
  {
    id: 4,
    category: "Price",
    value: "$150k & above",
  },
  {
    id: 5,
    category: "bedroom",
    value: "0BHK",
    label: "0 Bed",
  },
  {
    id: 6,
    category: "bedroom",
    value: "1BHK",
    label: "1 Bed",
  },
  {
    id: 7,
    category: "bedroom",
    value: "2BHK",
    label: "2 Beds",
  },
  {
    id: 8,
    category: "bedroom",
    value: "3+BHK",
    label: "3 Beds & Above",
  },
  {
    id: 9,
    category: "footprint",
    value: "500",
  },
  {
    id: 10,
    category: "footprint",
    value: "1000",
  },
  {
    id: 11,
    category: "footprint",
    value: "1500",
  },
  {
    id: 12,
    category: "footprint",
    value: "2000",
  },
  {
    id: 13,
    category: "approval",
    value: "City pre-approved",
  },
  {
    id: 14,
    category: "approval",
    value: "ACM pre-approved",
  },
  {
    id: 15,
    category: "approval",
    value: "Unapproved",
  },
  // {
  //   id: 15,
  //   category: "approval",
  //   value: "Custom",
  // },
];
