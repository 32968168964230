import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, CircularProgress } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { setUserSession } from "./Common";
import LoginHeader from "./LoginHeader";
import house from "../images/house.png";
import { setToken } from "../httpClient/ClientHelper";
import { apiInstance } from "./../httpClient/index";
import { showToast } from "../common/ToastMsg";

export default function Login() {
  let history = useHistory();
  const [passwordShow, setPasswordShow] = useState(false);
  const [error, setError] = useState("");
  const [loding, setLoading] = useState(false);

  useEffect(() => {
    // if(getToken() == null){
    //     history.push('/login')
    // }else{
    //     history.push('/')
    // }
  }, []);

  const togglePasswordVisiblity = () => {
    setPasswordShow(passwordShow ? false : true);
  };

  const initialValues = {
    password: "",
    email: "",
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const data = {
      email: values.email,
      password: values.password,
    };
    try {
      const res = await apiInstance.post(`auth/login`, data);
      setToken(res.data.token);
      setUserSession(
        res.data.token,
        res.data.data.name,
        res.data.data.email,
        res.data.data.phone,
        res.data.data
      );
      showToast("success", "login successfully");
      setLoading(false);
      history.replace("/");
    } catch (error) {
      if (error.response.status === 404) {
        setError(error.response.data.message);
      } else if (error.response.status === 400) {
        setError(error.response.data.message);
      } else {
        setError("Email or Password are invalid");
      }
      setLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid Email").required("Required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <>
      <LoginHeader />

      <section className="">
        <div className="d-flex align-items-center loginpage">
          <div className="imgside">
            <img src={house} alt="img" className="img-fluid" />
            <div className="imgcontent">
              <h1 className="mb-4">Build an ADU in record time</h1>
              <p>
                Want to build an ADU (accessory dwelling unit) without the long
                wait time? The Housing ADU app is your one-stop shop for
                everything you need while planning your build. Our repository of
                plans offers you choices which have been pre-approved, and saves
                you the time of going through the approval process alone.
              </p>
            </div>
          </div>
          <div className="content">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mx-auto">
                  <div className="form-section p-60">
                    <h1 className="mb-4 text-center">Login</h1>

                    <form onSubmit={formik.handleSubmit}>
                      <div className="form-group">
                        <label for="name-f">Email Address</label>
                        <input
                          className="form-control"
                          id="email"
                          name="email"
                          type="email"
                          {...formik.getFieldProps("email")}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div style={{ color: "#f00", textAlign: "left" }}>
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>

                      <div className="form-group">
                        <label for="name-f">Password</label>
                        <input
                          type={passwordShow ? "text" : "password"}
                          className="form-control"
                          name="password"
                          id="password"
                          label="password"
                          {...formik.getFieldProps("password")}
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <div style={{ color: "#f00", textAlign: "left" }}>
                            {formik.errors.password}
                          </div>
                        ) : null}

                        <span
                          className="hide-show"
                          onClick={togglePasswordVisiblity}
                        >
                          {passwordShow ? "Hide" : "Show"}
                        </span>
                      </div>

                      {error && (
                        <p style={{ color: "#F00", display: "block" }}>
                          {error}
                        </p>
                      )}

                      <p>
                        <Link to="/forgot-password">Forgot password?</Link>
                      </p>
                      <div className="d-flex">
                        <Button className="bluebtn mt-4" type="submit">
                          Login
                          {loding && (
                            <CircularProgress
                              size={15}
                              color="inherit"
                              style={{ marginLeft: "10px" }}
                            />
                          )}
                        </Button>
                      </div>
                    </form>

                    <div className="mt-4 text-center">
                      <p>
                        Don’t have an account?
                        <Link to="/register"> Register</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
