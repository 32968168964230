import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import * as Yup from "yup";
import { useFormik, FormikProvider, Form } from "formik";
import { Button, CircularProgress } from "@mui/material";
import { getToken, getData, removeFilterData } from "./Common";
import Header from "./Header";
import Footer from "./Footer";
import { apiInstance } from "./../httpClient/index";
import { showToast } from "../common/ToastMsg";

const CountrySelect = ({ value, onChange, labels, ...rest }) => {
  return (
    <select
      {...rest}
      value={value}
      onChange={(event) => onChange(event.target.value || undefined)}
    >
      <option value="">{labels["ZZ"]}</option>
      {getCountries().map((country) => (
        <option key={country} value={country}>
          +{getCountryCallingCode(country)}
        </option>
      ))}
    </select>
  );
};

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labels: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default function EditProfile() {
  const [error, setError] = useState("");
  const [userList, setUserList] = useState();
  const [country, setCountry] = useState("US");
  const [loding, setLoading] = useState(false);
  const token = getToken();
  const userData = getData();
  let history = useHistory();

  useEffect(() => {
    if (token) {
      getUser();
    } else {
      history.push("/");
    }
    removeFilterData();
  }, []);

  const handlecountry = (data) => {
    setCountry(data);
  };
  const getUser = async () => {
    setLoading(true);
    try {
      const res = await apiInstance.get(`user/${userData._id.toString()}`, {
        headers: {
          Authorization: token,
        },
      });

      setUserList(res.data.data);

      setCountry(res.data.data.phone.code);
      setLoading(false);
    } catch (e) {
      console.error("Error: ", e.response);
      setLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    fullname: Yup.string().required("Required"),
    email: Yup.string().email("Invalid Email").required("Required"),
    phone: Yup.string().required("Required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullname: userList !== undefined ? userList?.name : "",
      email: userList !== undefined ? userList?.email : "",
      phone: userList !== undefined ? userList?.phone.phoneNumber : "",
    },
    onSubmit: async (values) => {
      let data = {
        name: values.fullname,
        phoneNumber: values.phone,
        code: country,
        countryCode: getCountryCallingCode(country),
      };

      setLoading(true);
      try {
        await apiInstance.put(`user`, data, {
          headers: {
            Authorization: getToken(),
          },
        });
        setLoading(false);
        showToast("success", "Profile updated successfully.");
        history.push("/");
      } catch (error) {
        if (error.response.status === 404) {
          setError(error.response.data.message);
        } else if (error.response.status === 400) {
          setError(error.response.data.message);
        } else {
          setError("profile not updated");
        }
      }
    },
    validationSchema,
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <>
      <Header />
      <section className="p-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mx-auto">
              <div className="form-section p-60">
                <h1 className="mb-4 text-center">Edit profile</h1>

                <FormikProvider value={formik}>
                  <Form onSubmit={handleSubmit}>
                    {loding ? (
                      <>
                        <div className="col-lg-12">
                          <div
                            style={{
                              width: "100%",
                              height: "500px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress
                              size={60}
                              color="inherit"
                              style={{ marginLeft: "10px" }}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="form-group">
                          <label for="name-f">Your Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="fullname"
                            // onBlur={formik.handleBlur}
                            // label="fullname"
                            name="fullname"
                            // value={name}
                            // onChange={formik.handleChange}
                            {...getFieldProps("fullname")}
                            error={Boolean(touched.fullname && errors.fullname)}
                            helperText={touched.fullname && errors.fullname}
                          />
                        </div>

                        <div className="form-group">
                          <label for="name-f">Email Address</label>
                          <input
                            className="form-control"
                            id="email"
                            name="email"
                            type="email"
                            readOnly
                            {...getFieldProps("email")}
                          />
                          {/* {formik.touched.email && formik.errors.email ? <div style={{color:'#f00', textAlign:'left'}}>{formik.errors.email}</div> : null } */}
                        </div>

                        <div className="form-group">
                          <label for="name-f">Phone number</label>
                          <CountrySelect
                            labels={en}
                            value={country}
                            onChange={handlecountry}
                          />
                          <input
                            type="number"
                            placeholder="enter phone"
                            className="form-control phoneinput"
                            id="phone"
                            name="phone"
                            {...getFieldProps("phone")}
                          />
                          {formik.touched.phone && formik.errors.phone ? (
                            <div style={{ color: "#f00", textAlign: "left" }}>
                              {formik.errors.phone}
                            </div>
                          ) : null}
                        </div>

                        {error && (
                          <p style={{ color: "#F00", display: "block" }}>
                            {error}
                          </p>
                        )}

                        <Button className="bluebtn mt-4" type="submit">
                          Save Changes
                          {loding && (
                            <CircularProgress
                              size={15}
                              color="inherit"
                              style={{ marginLeft: "10px" }}
                            />
                          )}
                        </Button>
                      </>
                    )}
                  </Form>
                </FormikProvider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
