import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik, FormikProvider, Form } from "formik";
import { Button, CircularProgress } from "@mui/material";
import {
  getToken,
  getData,
  removeUserSession,
  removeFilterData,
} from "./Common";
import Header from "./Header";
import Footer from "./Footer";
import { apiInstance } from "./../httpClient/index";
import { showToast } from "../common/ToastMsg";

export default function ChangePassword() {
  const [error, setError] = useState("");
  const [userList, setUserList] = useState();
  const [passwordShow, setPasswordShow] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [ConfirmpasswordShow, setConfirmPasswordShow] = useState(false);
  const [loding, setLoading] = useState(false);
  let history = useHistory();

  useEffect(() => {
    if (getToken() == null) {
      removeUserSession();
      history.push("/login");
    } else {
      getUser();
    }
    removeFilterData();
  }, []);

  const togglePasswordVisiblity = (val) => {
    if (val === 1) {
      setPasswordShow(passwordShow ? false : true);
    } else if (val === 2) {
      setNewPasswordShow(newPasswordShow ? false : true);
    } else if (val === 3) {
      setConfirmPasswordShow(ConfirmpasswordShow ? false : true);
    }
  };

  const getUser = async () => {
    try {
      const res = await apiInstance.get(`user/${getData()._id}`);
      setUserList(res.data.data.email);
    } catch (e) {
      // setError('password not match')
      console.error("Error: ", e.response);
    }
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Required"),
    newpassword: Yup.string().required("Required"),
    // confirmnewpassword: Yup.string().required("Required"),
    confirmnewpassword: Yup.string().oneOf(
      [Yup.ref("newpassword"), null],
      "Passwords must match"
    ),
  });

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      password: "",
      newpassword: "",
      confirmnewpassword: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      const data = {
        // name: values.fullname,
        email: userList,
        oldpassword: values.password,
        newpassword: values.newpassword,
      };
      try {
        const response = await apiInstance.post(`auth/resetPassword`, data);
        history.push("/");
        setLoading(false);
        showToast("success", "Password change successfully.");
      } catch (error) {
        if (error.response.status === 404) {
          setError(error.response.data.message);
        } else if (error.response.status === 400) {
          setError(error.response.data.message);
        } else {
          setError("Something went wrong.please try again...");
        }
        setLoading(false);
      }
    },
    validationSchema,
  });
  const { handleSubmit } = formik;
  return (
    <>
      <Header />
      <section className="p-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mx-auto">
              <div className="form-section p-60">
                <h1 className="mb-4 text-center">Change password</h1>
                <FormikProvider value={formik}>
                  <Form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label for="name-f">Old password</label>
                      <input
                        type={passwordShow ? "text" : "password"}
                        className="form-control"
                        id="password"
                        //    onBlur={formik.handleBlur}
                        label="password"
                        name="password"
                        {...formik.getFieldProps("password")}
                        //    value={formik.values.password}
                        //    onChange={formik.handleChange}
                      />
                      {formik.touched.password && formik.errors.password ? (
                        <div style={{ color: "#f00", textAlign: "left" }}>
                          {formik.errors.password}
                        </div>
                      ) : null}

                      <span
                        className="hide-show"
                        onClick={() => togglePasswordVisiblity(1)}
                      >
                        {passwordShow ? "Hide" : "Show"}
                      </span>
                    </div>

                    <div className="form-group">
                      <label for="name-f">New password</label>
                      <input
                        type={newPasswordShow ? "text" : "password"}
                        className="form-control"
                        id="newpassword"
                        //    onBlur={formik.handleBlur}
                        label="newpassword"
                        name="newpassword"
                        {...formik.getFieldProps("newpassword")}
                        //    value={formik.values.password}
                        //    onChange={formik.handleChange}
                      />
                      {formik.touched.oldpassword &&
                      formik.errors.oldpassword ? (
                        <div style={{ color: "#f00", textAlign: "left" }}>
                          {formik.errors.oldpassword}
                        </div>
                      ) : null}

                      <span
                        className="hide-show"
                        onClick={() => {
                          togglePasswordVisiblity(2);
                        }}
                      >
                        {newPasswordShow ? "Hide" : "Show"}
                      </span>
                    </div>

                    <div className="form-group">
                      <label for="name-f">Confirm new password</label>
                      <input
                        type={ConfirmpasswordShow ? "text" : "password"}
                        className="form-control"
                        id="confirmnewpassword"
                        //    onBlur={formik.handleBlur}
                        label="confirmnewpassword"
                        name="confirmnewpassword"
                        {...formik.getFieldProps("confirmnewpassword")}
                        //    value={formik.values.password}
                        //    onChange={formik.handleChange}
                      />
                      {formik.touched.confirmnewpassword &&
                      formik.errors.confirmnewpassword ? (
                        <div style={{ color: "#f00", textAlign: "left" }}>
                          {formik.errors.confirmnewpassword}
                        </div>
                      ) : null}

                      <span
                        className="hide-show"
                        onClick={() => togglePasswordVisiblity(3)}
                      >
                        {ConfirmpasswordShow ? "Hide" : "Show"}
                      </span>
                    </div>

                    {error && (
                      <p style={{ color: "#F00", display: "block" }}>{error}</p>
                    )}

                    <Button className="bluebtn mt-4" type="submit">
                      Save Changes
                      {loding && (
                        <CircularProgress
                          size={15}
                          color="inherit"
                          style={{ marginLeft: "10px" }}
                        />
                      )}
                    </Button>
                  </Form>
                </FormikProvider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
