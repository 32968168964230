import React, { useState, useEffect } from "react";
import { apiInstance } from "./../httpClient/index";
import { getToken } from "./Common";
import { Link } from "react-router-dom";
import heartfill from "../images/heart-fill.png";
import heartline from "../images/heart-line.png";
import { showToast } from "../common/ToastMsg";
import { getIsFilter, getFilterData } from "./Common";

export default function AduListComponent(props) {
  const [error, setError] = useState("");
  const [isFilter, setIsFilter] = useState();
  const [filterData, setFilterData] = useState();
  const [isFavourite, setIsFavourite] = useState(props.data.isfavoritre);

  const token = getToken();

  useEffect(() => {
    // setIsFilter(props.isFilter);
    setIsFilter(getIsFilter());
    setFilterData(getFilterData());
  }, []);

  const addFavourite = async (id, fav) => {
    if (token === null) {
      showToast("error", "you have to login first");
    } else if (isFavourite === false) {
      try {
        const res = await apiInstance.post(`favorite/create/${id}`);
        setIsFavourite(!isFavourite);

        showToast("success", "Adu added in favourite sucessfully");
      } catch (error) {
        if (error.response.status === 404) {
          setError(error.response.data.message);
        } else if (error.response.status === 400) {
          setError(error.response.data.message);
        } else {
          setError("Something went wrong.please try again.");
        }
      }
    } else {
      try {
        await apiInstance.delete(`favorite/delete/adu/${id}`);
        setIsFavourite(!isFavourite);

        showToast("success", "Adu removed from favourite sucessfully");
      } catch (error) {
        setError("Something went wrong.please try again.");
      }
    }
  };

  return (
    <div className="plan-grid">
      <div className="planbox">
        <Link
          to={{
            pathname: "/product-detail",
            state: props.data,
            filterData: props.isFilter,
          }}
        >
          <div className="box">
            <div className="img-box">
              <img src={props.data.image[0]} alt="img1" />
            </div>
            <div className="content">
              <div className="d-flex justify-content-between">
                <h4>{props.data.adu_name}</h4>
                {props?.data?.price && props.data.price > 0 ? (
                  <h4 className="price">${props.data.price}</h4>
                ) : null}
              </div>
              {props?.data?.lotSize ? (
                <p>Lot size: {props.data.lotSize} Sq. Ft</p>
              ) : null}
            </div>
          </div>
        </Link>

        <span
          className="liked"
          onClick={() => addFavourite(props.data._id, props.data.isfavoritre)}
        >
          {isFavourite ? (
            <img src={heartfill} alt="img" />
          ) : (
            <img src={heartline} alt="img" />
          )}
        </span>
      </div>
    </div>
  );
}
