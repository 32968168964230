import React, { useState, useEffect } from "react";
import { styled, alpha } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Link,
  Drawer,
  MenuItem,
  Avatar,
  Menu,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as RouterLink } from "react-router-dom";
import logo1 from "../images/logo.png";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";
import { getToken, removeUserSession, getData } from "./Common";
import { apiInstance } from "./../httpClient/index";

const headersData = [
  {
    label: "ADU Plans",
    href: "/adu-plans",
    isLoginDisplay: true,
    isNotLoginDisplay: true,
  },
  {
    label: "Contact",
    href: "/contact",
    isLoginDisplay: true,
    isNotLoginDisplay: true,
  },
  {
    label: "Login",
    href: "/login",
    isLoginDisplay: false,
    isNotLoginDisplay: true,
  },
  {
    label: "Saved plans",
    href: "/saved-plans",
    isLoginDisplay: true,
    isNotLoginDisplay: false,
  },
  {
    label: "Create An Account",
    href: "/register",
    isLoginDisplay: false,
    isNotLoginDisplay: true,
  },
];

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "#fff !important",
    // paddingRight: "79px",
    // paddingLeft: "118px",
    paddingRight: "80px",
    paddingLeft: "80px",
    paddingTop: "10px",
    paddingBottom: "10px",
    boxShadow: "none !important",
    "@media (max-width: 1024px)": {
      paddingLeft: "45px",
      paddingRight: "45px",
    },
    "@media (max-width: 900px)": {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
    "@media (max-width: 767px)": {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  logo: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#FFFEFE",
    textAlign: "left",
  },
  menuButton: {
    fontWeight: "600 !important",
    size: "18px",
    marginLeft: "38px !important",
    color: "#000 !important",
    fontSize: "18px !important",
    lineHeight: "21px !important",
    textTransform: "capitalize !important",
    transition: "none !important",
    WebkitTapHighlightColor: "none !important",
    "&:hover": {
      backgroundColor: "#fff !important",
    },
    "&:focus": {
      backgroundColor: "#fff !important",
    },
  },
  menuButtonFill: {
    fontWeight: "600 !important",
    size: "18px",
    marginLeft: "38px !important",
    color: "#fff !important",
    fontSize: "18px !important",
    lineHeight: "21px !important",
    background: "#2F80ED !important",
    borderRadius: "10px !important",
    textTransform: "capitalize !important",
    padding: "14px 25px !important",
  },
  mobilemenuButtonFill: {
    background: "#2F80ED !important",
    color: "#fff !important",
    borderRadius: "10px !important",
  },

  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "20px 30px",
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function stringAvatar(name) {
  const res = name
    .toUpperCase()
    .trim()
    .split(" ")
    .map((l) => l[0])
    .slice(0, 2);

  return {
    children: res,
  };
}

export default function Header() {
  const history = useHistory();
  const [name, setName] = useState("username");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const {
    header,
    menuButton,
    menuButtonFill,
    toolbar,
    drawerContainer,
    mobilemenuButtonFill,
  } = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    removeUserSession();
    history.push("/login");
  };

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    if (getToken()) {
      getUser();
    }
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const getUser = async () => {
    try {
      const res = await apiInstance.get(`user/${getData()._id}`, {
        headers: {
          Authorization: getToken(),
        },
      });
      setName(res.data.data.name);
      // setName(response.data.data.name)
      // setUserList(res.data.data);
      // setCountry(res.data.data.phone.code);
    } catch (e) {
      console.error("Error: ", e.response);
    }
  };

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {femmecubatorLogo}
        <div>{getMenuButtons()}</div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className="d-flex justify-content-between">
        <div>{femmecubatorLogo}</div>
        <IconButton
          className="menucolor"
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    const token = getToken();

    return (
      <>
        {token ? (
          <>
            <Button
              id="demo-customized-button"
              aria-controls="demo-customized-menu"
              aria-haspopup="true"
              // aria-expanded={open ? 'true' : undefined}
              variant="contained"
              disableElevation
              // onClick={handleClick}
              className="mobile-userprofile"
              margin="auto"
              // endIcon={<KeyboardArrowDownIcon />}
            >
              {name}
            </Button>
            <p className="username">{name}</p>
            <Divider />

            {headersData.map(({ label, href, isLoginDisplay }) => {
              return (
                <>
                  {isLoginDisplay ? (
                    <>
                      <Link
                        {...{
                          component: RouterLink,
                          to: href,
                          color: "inherit",
                          style: { textDecoration: "none" },
                          key: label,
                        }}
                      >
                        <MenuItem>{label}</MenuItem>
                      </Link>
                    </>
                  ) : null}
                </>
              );
            })}

            <Link>
              <MenuItem to="/edit-profile" component={RouterLink}>
                Edit Profile
              </MenuItem>
            </Link>

            <Link>
              <MenuItem to="/change-password" component={RouterLink}>
                Change Password
              </MenuItem>
            </Link>

            <Link>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Link>
          </>
        ) : (
          <>
            {headersData.map(
              ({ label, href, isLoginDisplay, isNotLoginDisplay }) => {
                return (
                  <>
                    {!isLoginDisplay || isNotLoginDisplay ? (
                      <>
                        <Link
                          {...{
                            component: RouterLink,
                            to: href,
                            color: "inherit",
                            style: { textDecoration: "none" },
                            key: label,
                            // className: label === "Create An Account" ? mobilemenuButtonFill : null
                          }}
                        >
                          <MenuItem
                            {...{
                              className:
                                label === "Create An Account"
                                  ? mobilemenuButtonFill
                                  : null,
                            }}
                          >
                            {label}
                          </MenuItem>
                        </Link>
                      </>
                    ) : null}
                  </>
                );
              }
            )}
          </>
        )}
      </>
    );
  };

  const femmecubatorLogo = (
    <Link to="/" component={RouterLink}>
      <img src={logo1} alt="logo" className="logo-img" />
    </Link>
  );

  const getMenuButtons = () => {
    const token = getToken();
    return (
      <>
        {token ? (
          <>
            <div className="d-flex">
              {headersData.map(({ label, href, isLoginDisplay }, index) => {
                return (
                  <div
                    key={index.toString()}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {isLoginDisplay ? (
                      <>
                        <Button
                          className="menuButton1"
                          {...{
                            key: label,
                            color: "inherit",
                            to: href,
                            component: RouterLink,
                            className:
                              label === "Create An Account"
                                ? menuButtonFill
                                : menuButton,
                          }}
                        >
                          {label}
                        </Button>
                      </>
                    ) : null}
                  </div>
                );
              })}
              <IconButton
                id="demo-customized-button"
                aria-controls="demo-customized-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                className="userprofile"
                // endIcon={<KeyboardArrowDownIcon />}
                // {{...stringAvatar('Kent Dodds')}}
              >
                <Avatar
                  className="avtarimg"
                  {...stringAvatar(`${name}`)}
                  color="#000"
                />
              </IconButton>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem to="/edit-profile" component={RouterLink}>
                  <PersonIcon />
                  Edit Profile
                </MenuItem>
                <MenuItem
                  to="/change-password"
                  component={RouterLink}
                  disableRipple
                >
                  <LockIcon />
                  Change password
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={handleLogout} disableRipple>
                  <LogoutIcon />
                  Logout
                </MenuItem>
              </StyledMenu>
            </div>
          </>
        ) : (
          <>
            {headersData.map(
              ({ label, href, isLoginDisplay, isNotLoginDisplay }) => {
                return (
                  <>
                    {!isLoginDisplay || isNotLoginDisplay ? (
                      <>
                        <Button
                          className="menuButton1"
                          {...{
                            key: label,
                            color: "inherit",
                            to: href,
                            component: RouterLink,
                            className:
                              label === "Create An Account"
                                ? menuButtonFill
                                : menuButton,
                          }}
                        >
                          {label}
                        </Button>
                      </>
                    ) : null}
                  </>
                );
              }
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <header className="container">
        <AppBar className={header}>
          {mobileView ? displayMobile() : displayDesktop()}
        </AppBar>
      </header>
    </>
  );
}
